<template>
  <div class="tableList">
    <list-search :childData="childData" @onSearch="search"  @clearSearch="search"></list-search>
     <global-table ref="addDetailtable"  :tableField="tableField"  :tableData="tableData" ></global-table>
  </div>
</template>

<script>
import { requestForm } from '@/assets/js/http.js'
import Component from '@/assets/js/components.js'
import { SearchList, SearchData, tableField, tableData } from './createonlineInvoicesdialog'
export default {
  name: 'CreateonlineInvoicesdialog',
  props: ['addData'],
  components: Component.components,
  data () {
    return {
      tableField: tableField,
      tableData: tableData,
      searchdata: SearchData,
      pageNum: 1,
      pageSize: 99999,
      childData: { searchList: SearchList, searchData: SearchData }
    }
  },
  created () {
    this.CreateonlineInvoicesdialogList()
  },
  methods: {
    CreateonlineInvoicesdialogList () {
      const obj = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        corporateName: this.addData.corporateName, // 公司名称
        statementType: this.addData.statementType, // 对账单类型
        billingParty: this.addData.billingParty, // 开票方
        currency: this.addData.currency, // 币种
        consignmentOrNot: this.addData.consignmentOrNot // 是否寄售:0是,1不是
      }
      requestForm('/api/reconciliation/invoiceTotal/queryCreateAll', 'post', obj).then((res) => {
        if (res.code === '200') {
          this.tableData = res.data
        }
      })
    },
    search: function (data) {
      data.pageNum = this.pageNum
      data.pageSize = this.pageSize
      const obj = {
        corporateName: this.addData.corporateName, // 公司名称
        statementType: this.addData.statementType, // 对账单类型
        billingParty: this.addData.billingParty, // 开票方
        currency: this.addData.currency, // 币种
        consignmentOrNot: this.addData.consignmentOrNot // 是否寄售:0是,1不是
      }
      this.searchdata = { ...data, ...obj }
      requestForm('/api/reconciliation/invoiceTotal/queryCreateAll', 'post', this.searchdata).then((Response) => {
        if (Response.code === '200') {
          this.tableData = Response.data
        }
      })
    }
  }
}
</script>
<style scoped>
.tableList{
padding: 0px;
}
</style>
