import md5 from 'js-md5'
export var SearchList = [
  { labe: '对账单编号', code: 'number', type: 'input' }
]
export var SearchData = {
  number: ''
}
export var tableField = [
  { selectType: 'selection', width: '', type: 'input' },
  { label: '对账单编号', code: 'number', type: 'input', width: '120' },
  { label: '对账单含税金额', code: 'remainingAmount', type: 'amount', align: 'right', width: '120' },
  { label: '剩余无票金额', code: 'amountIncludingTax', type: 'amount', align: 'right', width: '120' },
  { label: '内部项目号', code: 'projectNumber', type: 'input', width: '110' },
  { label: '内部项目标识', code: 'projectIdentification', type: 'input', width: '110' },
  { label: '行数', code: 'numberOfRows', type: 'input', width: '' },
  {
    label: '创建网上发票状态',
    code: 'createOnlineInvoice',
    type: 'function',
    width: '160',
    handle: (index, data) => {
      const color = md5(data.createOnlineInvoice + '').substring(0, 6)
      const label = data.createOnlineInvoice === 0 ? '完全创建' : data.createOnlineInvoice === 1 ? '未创建' : '部分创建'
      const tblab = `<div class="dotlab"><div class="dot" style="background:#${color}"></div>${label}</div>`
      return tblab
    }
  }
]
export var tableData = []
